import {EEntityType, EServiceCompanyPermission} from '@mcal/core';
import type {ILinkContainer} from '@mcal/core-react-web';

const HOME_LINKS: ILinkContainer = {
    entityType: null,
    links: [
        {
            icon: 'apartment',
            label: 'Home',
            link: '/',
            permissions: []
        }
    ]
};

const SERVICE_COMPANY_LINKS: ILinkContainer = {
    entityType: EEntityType.ServiceCompany,
    links: [
        {
            icon: 'explore',
            label: 'Explore',
            link: '/service-companies/:serviceCompanyId/overview',
            permissions: []
        },
        {
            icon: 'apartment',
            label: 'Sites',
            link: '/service-companies/:serviceCompanyId/sites',
            permissions: []
        },
        {
            icon: 'team',
            disabled: process.env.DISABLED_FEATURES === 'true',
            label: 'Teams',
            link: '/service-companies/:serviceCompanyId/teams',
            permissions: [EServiceCompanyPermission.TeamView]
        },
        {
            icon: 'settings',
            label: 'Settings',
            link: '/service-companies/:serviceCompanyId/settings',
            permissions: [
                EServiceCompanyPermission.SettingsGeneralView,
                EServiceCompanyPermission.SettingsGeneralEdit,
                EServiceCompanyPermission.SettingsDocumentsView,
                EServiceCompanyPermission.SettingsDocumentsEdit,
                EServiceCompanyPermission.SettingsNotificationsView,
                EServiceCompanyPermission.SettingsNotificationsEdit,
                EServiceCompanyPermission.SettingsFeatureModulesView,
                EServiceCompanyPermission.SettingsFeatureModulesEdit,
                EServiceCompanyPermission.SettingsBillingView,
                EServiceCompanyPermission.SettingsBillingEdit
            ]
        }
    ]
};

const SITE_LINKS: ILinkContainer = {
    entityType: EEntityType.Site,
    links: [
        {
            icon: 'apartment',
            label: 'Site',
            link: '/site/:siteId/overview',
            permissions: []
        },
        {
            icon: 'doorSliding',
            label: 'Elevators',
            link: '/site/:siteId/elevators',
            permissions: []
        },
        {
            icon: 'team',
            disabled: process.env.DISABLED_FEATURES === 'true',
            label: 'Teams',
            link: '/site/:siteId/teams',
            permissions: []
            // permissions: [ESitePermission.TeamView]
        },
        {
            icon: 'settings',
            label: 'Settings',
            permissions: [],
            link: '/site/:siteId/settings'
            // permissions: [
            //     ESitePermission.SettingsGeneralView,
            //     ESitePermission.SettingsGeneralEdit,
            //     ESitePermission.SettingsNotificationsView,
            //     ESitePermission.SettingsNotificationsEdit,
            //     ESitePermission.SettingsFeatureModulesView,
            //     ESitePermission.SettingsFeatureModulesEdit,
            //     ESitePermission.SettingsBillingView,
            //     ESitePermission.SettingsBillingEdit
            // ]
        }
    ]
};

export {HOME_LINKS, SERVICE_COMPANY_LINKS, SITE_LINKS};
